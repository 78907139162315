.load-page{
    overflow: hidden;
}
.loader{
    padding: 15px;
    width: 300px;
    height: 300px;
    margin: auto;
}
.loader div{
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    border: 2px solid rgb(224, 137, 205);
    border-top: 2px solid rgb(28, 240, 197);
    box-shadow: 0 0 4px rgb(242, 90, 222);
    border-radius: 50%;
    animation: rotate 15s linear infinite alternate-reverse;
}
.loard-logo{
    max-width: 150Px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.4;
}
@keyframes rotate{
    50%{
        transform: rotate(80deg);
    }
    100%{
        transform: rotate(360deg);
    }
}