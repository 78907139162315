.AksharaMalawa-main-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #161623;
    min-height: 100vh;
}
.AksharaMalawa-main-box::before{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(135, 14, 91),rgb(60, 71, 4));
    clip-path: circle(25% at left 60%);
}
.AksharaMalawa-main-box::after{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(32, 7, 197),rgb(74, 175, 6));
    clip-path: circle(8% at 65% 60%);  
}
#v-text-card-fristitem{
    width: 300px;
    background: rgba(207, 117, 204, 0.1);
}