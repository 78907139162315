.myProgressBar{
    min-height: 40vh;
    background: #161922;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.myProgressBar-wrapper{
    position: relative;
    width: 90vw;
    display: flex;
    justify-content: space-around;
    gap: 25px;
}
.myProgressBar-container{
    width: 28vmin;
    height: 28vmin;
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: space-around;
    padding: 1em 0;
    position: relative;
    font-size: 16px;
    border-radius: .5em;
    background: #21242b;
    border-bottom: 10px solid #18f98f;
    border-top: 1px solid rgba(255, 255, 255, 0.359);
    border-left: 1px solid rgba(255, 255, 255, 0.359);
    overflow: hidden;
    -webkit-box-reflect: below 1px linear-gradient(transparent,rgba(0, 0, 0, 0.047));
    transition: .4s;
}
.myProgressBar-container:hover{
    transform: translateY(-10px);
}
.myProgressBar-container::before{
    content: '';
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.047);
    transform: skew(25deg);
}
.myProgressBar-container i{
    color: #18f98f;
    font-size: 2.5em;
    text-align: center;
}
.myProgressBar-container span.upnums{
    display: grid;
    place-items: center;
    font-weight: 600;
    font-size: 3em;
}
.myProgressBar-container span.upnumsText{
    color: rgb(205, 199, 199);
    font-size: 1.2em;
    text-align: center;
    pad: 0.7em 0;
    font-weight: 400;
    line-height: 0;
}

@media screen and (max-width: 1024px){
    .myProgressBar-wrapper{
        width: 80vw;
    }
    .myProgressBar-container{
        height: 30vmin;
        width: 30vmin;
        font-size: 14px;
    }
}
@media screen and (max-width: 768px){
    .myProgressBar-wrapper{
        width: 90vw;
        flex-wrap: wrap;
        gap: 30px;
    }
    .myProgressBar-container{
        height: 26vmin;
        width: calc(50% -40%);
        font-size: 14px;
    }
}
@media screen and (max-width: 480px){
    .myProgressBar-wrapper{
        gap: 15px;
    }
    .myProgressBar-container{
        height: 25vmin;
        width: 80%;
        font-size: 8px;
    }
}
.forPlussmark{
    position: relative;
    display: flex;
    justify-content: center;
}
.forPlussmark p{
    font-size: 25px;
}