.SecPart-main-box{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    background: #2c1c34;
}
.G-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}
.G-gcard{
    position: relative;
    width: 320px;
    height: 450px;
    margin: 40px;
    background: #287bff;
    border-radius: 20px;
    border-bottom-left-radius: 160px;
    border-bottom-right-radius: 160px;
    box-shadow: 0 15px 0 #fff,
    inset 0 -15px 0 rgba(255,255,255,0.25), 0 45px 0 rgba(0,0,0,0.15);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.G-gcard::before{
    content: '';
    position: absolute;
    top: -140px;
    left: -100px;
    width: 100%;
    height: 120%;
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2));
    transform: rotate(30deg);
    pointer-events: none;
    filter: blur(5px);
}
.G-gcard:nth-child(2)
{
    background: linear-gradient(to bottom, #ff2ae0, #645bf6);
}
.G-gcard:nth-child(3)
{
    background: linear-gradient(to bottom, #ffec61, #f321d7);
}
.G-gcard:nth-child(4)
{
    background: linear-gradient(to bottom, #24ff72, #9a4eff);
}
.G-gcard:nth-child(5)
{
    background: linear-gradient(to bottom, #ff8324, #ff4ef9);
}
.G-icon{
    position: relative;
    width: 140px;
    height: 120px;
    background: #2c1c34;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 0 10px 0 rgba(0, 0, 0, 0.1),
    inset 0 -8px 0 #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
}
.G-icon::before{
    content: '';
    position: absolute;
    top: 0;
    background: transparent;
    left: -50px;
    width: 50px;
    height: 50px;
    border-top-right-radius: 50px;
    box-shadow: 15px -15px 0 15px #2c1c34;
}
.G-icon::after{
    content: '';
    position: absolute;
    top: 0;
    background: transparent;
    right: -50px;
    width: 50px;
    height: 50px;
    border-top-left-radius: 50px;
    box-shadow: -15px -15px 0 15px #2c1c34;
}
.G-icon i{
    position: relative;
    color: rgb(244, 239, 239);
    font-size: 5em;
    z-index: 6000;
    text-shadow: 0 0 6px;
}
.G-Content{
    position: absolute;
    left: 0;
    width: 100%;
    padding: 30px;
    text-align: center;
    transition: .4s;
}
.G-Content:hover{
    transform: translateY(-10px);
}
.G-Content h3{
    font-size: 2em;
    color: #fff;
    margin-bottom: 10px;
    font-family: 'Noto serif Sinhala',sans-serif;
    text-shadow: 0 0 5px #333;
}
.G-Content p{
    color: rgb(244, 241, 241);
    line-height: 1.5em;
    font-family: 'Noto serif Sinhala',sans-serif;
    font-size: 15px;
    text-shadow: 0 0 5px #333;
}