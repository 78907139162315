.v-main-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #161623;
    min-height: 100vh;
}
.v-main-box::before{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(173, 3, 3),rgb(140, 2, 140));
    clip-path: circle(25% at right 60%);
}
.v-main-box::after{
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(2, 155, 155),rgb(210, 9, 210));
    clip-path: circle(10% at 5% 35%);  
}

.v-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.v-text-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    z-index: 1;
    margin-top: 30px;
    width: 100%;
}
.v-text-container h1{
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 3em;
    padding-bottom: 15px;
    font-family: 'Noto serif Sinhala',sans-serif;
}
.v-text-container h2{
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 1.5em;
    padding-bottom: 15px;
    font-family: 'Noto serif Sinhala',sans-serif;
}
.v-text-container h4{
    display: flex;
    justify-content: center;
    color: rgb(244, 225, 225);
    font-size: 15px;
    padding-bottom: 14px;
    font-family: 'Noto serif Sinhala',sans-serif;
}
.v-cards{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    flex-wrap: wrap;
    z-index: 1;
    padding: 15px;
}

.v-cards .v-text-card{
    position: relative;
    width: 200px;
    height: 300px;
    margin: 30px;
    box-shadow: 20px 20px 50px rgba(0,0,0,0.5);
    border-radius: 15px;
    background: rgba(255,255,255,0.1);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    backdrop-filter: blur(5px);
    cursor: pointer;
    transition: 0.4s;
}
.v-cards .v-text-card:hover{
    transform: scale(1.1);
}
.v-cards .v-text-card .v-content{
    padding: 20px;
    text-align: center;
}

.v-cards .v-text-card .v-content h2{
    position: absolute;
    top: -5px;
    right: 30px;
    font-size: 8em;
    color: rgba(255,255,255,0.03);
    pointer-events: none;
}
.v-cards .v-text-card .v-content h3{
    font-size: 1.6em;
    color: #fff;
    z-index: 1;
    padding-top: 10px;
    margin-bottom: 10px;
    font-family: 'Noto serif Sinhala',sans-serif;
}
.v-cards .v-text-card .v-content h5{
    font-size: 1em;
    color: #fff;
    font-weight: 300;
    
}
.v-cards .v-text-card .v-content a{
    position: relative;
    display: inline-block;
    padding: 8px 20px;
    margin-top: 15px;
    background: rgb(231, 224, 224);
    color: #000;
    border-radius: 20px;
    text-decoration: none;
    font-weight: 500;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: .4s;
}
.v-cards .v-text-card .v-content a:hover{
    opacity: 0.7;
}

#v-text-card-lastitem{
    width: 300px;
}
