.newheader{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(21, 20, 25);
    overflow: hidden;
}
.listofnewmenu{
    margin: 60px;
}
.newheader ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.newheader ul li{
    display: flex;
    position: relative;
    list-style: none;
    padding: 25px;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.newheader ul li i{
    padding: 15px;
    color: rgb(195, 176, 176);
    font-size: 1.5em;
    margin-right: 15px;
    text-shadow: 0 5px 15px rgba(156, 151, 151, 0.5);
}
.newheadlink{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 3em;
    color: rgba(236, 229, 229, 0.94);
    font-weight: 700;
    transition: 0.5s;
    transition-delay: 0.5s;
    text-shadow: 0 5px 15px rgba(135, 133, 133, 0.5);
}
.newheadlink:hover{
    color: rgba(255,255,255,0.1);
    transform: translateY(-15px);
    transition-delay: 0s;
}
.newheadlink::before{
    content: attr(data-text);
    position: absolute;
    bottom: -25px;
    pointer-events: none;
    z-index: 1;
    font-size: 0.35em;
    color: #fff;
    font-weight: 500;
    letter-spacing: 100px;
    opacity: 0;
    transition: 0.5s;
    text-shadow: 0 0 10px #00b3ff,
    0 0 30px #00b3ff,
    0 0 80px #00b3ff;
    font-family: 'Poppins',sans-serif;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.newheadlink:hover::before{
    font-weight: 600;
    letter-spacing: 2px;
    opacity: 1;
    transition-delay: 0.2s;
}
.logoHead{
    position: absolute;
    width: 60%;
    display: flex;
    justify-content: center;
    opacity: 0.03;
    animation: animation 2s ease-in-out infinite;
}
.logoHead-titlenew{
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}
.newheader h2{
    top: 40px;
    position: absolute;
    font-family: 'Poppins',sans-serif;
    font-size: 5em;
    
}
.newheader h2:nth-child(1){
    color: transparent;
    -webkit-text-stroke: 2px #00b3ff;
    text-shadow: 0 0 80px #00b3ff;
    
}
.newheader h2:nth-child(2){
    color: #00b3ff;
    animation: animatelogoHeadnewtitle 4s ease-in-out infinite;
}
@keyframes animatelogoHeadnewtitle{
    0%,100%{
        clip-path: polygon(0 47%, 17% 46%, 29% 52%, 46% 63%, 66% 67%, 83% 64%, 100% 55%, 100% 100%, 0 100%);
    }
    50%{
        clip-path: polygon(0 58%, 13% 67%, 29% 69%, 49% 63%, 66% 50%, 84% 46%, 100% 50%, 100% 100%, 0 100%);
    }
        
}
@media (max-width: 580px) {
    .listofnewmenu{
        margin: 60px;
    }
    .newheader ul li{
        padding: 20px;
    }
    .newheader ul li i{
        padding: 5px;
        font-size: 1em;  
    }
    .newheadlink{
        font-size: 1.5em;
    }
    .logoHead-titlenew{
        padding: 35px;
        margin-bottom: 20px;
    }
    .newheader h2{
        top: 70px;
        font-size: 2.7em;  
    }
}