*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
.L-body{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: rgb(4, 1, 1);
}
.L-section{
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    overflow: hidden;
}
.L-section::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000,#f9004d,#000);
    animation: animate 5s linear infinite;
}
@keyframes animate{
    0%
    {
        transform: translateY(-100%);
    }
    100%
    {
        transform: translateY(100%);
    }
}

.L-section span{
    position: relative;
    display: block;
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
    background: #0a0a18;
    z-index: 2;
    transition: 1.5s;
}
.L-section span:hover{
    background: #ad093d;
    transition: 0s;
}


.L-section .login-page{
    position: absolute;
    width: 400px;
    background: rgb(20, 18, 28);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0,0,0,0.5);
}
.L-section .login-page .login-page-content{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}
.login-page-content h2{
    font-size: 2em;
    color: #d91654;
}
.L-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.L-form .L-inputBx{
    position: relative;
    width: 100%;
}
.L-form .L-inputBx input{
    position: relative;
    width: 100%;
    background: #333;
    border: none;
    outline: none;
    padding: 25px 10px 7.5px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    font-size: 1em;
}
.L-form .L-inputBx i{
    position: absolute;
    left: 0;
    padding: 15px 10px;
    font-style: normal;
    color: #aaa;
    transition: 0.5s;
    pointer-events: none;
}
.L-form .L-inputBx input:focus ~ i,
.L-form .L-inputBx input:valid ~ i{
    transform: translateY(-7.5px);
    font-size: 0.8em;
    color: #fff;
}

.L-form .L-links{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.L-form .L-links h4{
    color: rgb(227, 215, 215);
    text-decoration: none;
    font-size: 12px;
}
#reglink{
    color: #df205c;
    font-size: 18px;
    font-style: italic;
    transition: 0.4s;
}
#reglink:hover{
    transform: scale(1.1);
}
.L-form .L-inputBx input[type="submit"]{
    padding: 10px;
    background-color: #f9004d;
    color: #111;
    font-weight: 600;
    font-size: 1.25em;
    letter-spacing: 0.05em;
    cursor: pointer;
    transition: 0.4s;
}
.L-form .L-inputBx input[type="submit"]:hover{
    transform: scale(1.1);
}

@media (max-width: 900px){
    .L-section span
    {
        width: calc(10vw - 2px);
        height: calc(10vw - 2px);
    }
}

#maxtitle{
    font-size: 40px;
}

@media (max-width: 650px){
    .L-section span
    {
        width: calc(20vw - 2px);
        height: calc(20vw - 2px);
    }
    .login-page-content h2{
        font-size: 1em;
    }
    #maxtitle{
        font-size: 1.5em;
    }
    .L-form .L-inputBx input{
        font-weight: 400;
        font-size: .8em;
        height: 40px;
        background: #32313181;
    }
    .L-section .login-page{
        width: 250px;
        padding: 20px;
    }
    .L-section .login-page .login-page-content{
        gap: 25px;
    }
    .L-form{
        gap: 25px;
    }
    .L-form .L-inputBx{
        position: relative;
        width: 100%;
    }
    .L-form .L-inputBx i{
        font-size: .8em;
    }
    .L-form .L-inputBx input:focus ~ i,
    .L-form .L-inputBx input:valid ~ i{
        font-size: 0.6em;
    }
    .L-form .L-links h4{
        color: rgb(185, 176, 176);
        font-size: 8px;
    }
    #reglink{
        font-size: 14px;
    }
    .L-form .L-inputBx input[type="submit"]{
        padding: 5px;
        font-size: .9em;
    }
}